/* FONTS */
@font-face {
  font-family: 'Messina Sans';
  src: url('/font/MessinaSans-Bold.woff2') format('woff2'),
       url('/font/MessinaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('/font/MessinaSans-Regular.woff2') format('woff2'),
       url('/font/MessinaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('/font/MessinaSans-Light.woff2') format('woff2'),
       url('/font/MessinaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v20/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyx615Mjs.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v20/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v20/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyx615Mjs.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v20/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --ion-font-family: 'Messina Sans', sans-serif !important;
  --ion-toolbar-background: var(--ion-color-dark);
  --ion-toolbar-color: var(--ion-color-primary);
}

.monospace {
  font-family: 'Inconsolata', monospace !important;
}

ion-toolbar {
  text-align: center;
}

.full-width {
  width: 100%;
}

.centered-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.full-splash {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.full-splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.visibility-hidden {
  visibility: hidden !important;
}

.disabld-text {
  opacity: .3;
}

@-webkit-keyframes  blinker {
  50% {
    opacity: 0;
  }
}

@-moz-keyframes  blinker {
  50% {
    opacity: 0;
  }
}

@-o-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blinker 2s ease-in-out infinite;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-o-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-moz-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

.fadein {
  animation: fadeIn 3s;
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

@-webkit-keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

@-o-keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

@-moz-keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

.fadeout {
  animation: fadeOut 3s;
}

.round-btn-large {
  width: 120px;
  height: 120px;
  font-size: 28px;
}

.round-btn-small {
  width: 80px;
  height: 80px;
  font-size: 16px;
}

.powered-by {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: .5rem;
  font-style: italic;
}

.powered-by.dark {
  color: var(--ion-color-dark);
}

.powered-by.medium {
  color: var(--ion-color-medium);
}

.powered-by.light {
  color: var(--ion-color-light);
}

.powered-by a {
  color: var(--ion-color-tertiary);
}

.superbold {
  font-weight: 900;
}