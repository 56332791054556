#map-outer-box {
  /* padding: .4rem; */
  /* border: 1px solid var(--ion-color-dark); */
  width: 100%;
}

.spot-popup .leaflet-popup-content-wrapper {
  background-color: var(--ion-color-light);
  width: 250px;
}
.spot-popup .leaflet-popup-content {
  margin: 30px auto !important;
}

.spot-popup .leaflet-popup-tip {
  background: var(--ion-color-light);
}

.spot-popup .leaflet-popup-close-button {
  font-size: 2rem !important;
  color: var(--ion-color-light-contrast) !important;
  margin: .5rem !important;
  font-weight: 900 !important;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

#active-listen-map .leaflet-marker-icon {
  pointer-events: none;
}