#main-grid {
  height: 100%;
  max-width: 1200px;
}

#main-grid > ion-row {
  height: 100%;
}

.home-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#record-fab {
  --color: var(--ion-color-tertiary);
}

#listen-fab {
  --color: var(--ion-color-success);
}