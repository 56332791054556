#listen-page-grid {
  height: 100%;
  padding: 0;
}

#listen-page-grid ion-row {
  height: 100%;
}

#listen-page-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
}