div.introduction-splash {
  background-color: var(--ion-color-primary-tint);
}

#mf-logo {
  width: 120px;
  margin-bottom: 2rem;
}

div.paragraph-container {
  width: 60%;
  text-align: center;
}

.dark-alert-button {
  color: var(--ion-color-dark-tint) !important;
}